import React from "react"
import Cookie from "js-cookie"
import {
  defaultLanguage as configStoreDefaultLanguage,
  selectedLanguage as configStoreSelectedLanguage,
  setSelectedLanguage
} from "../stores/ConfigStore";
import {supportedLanguages} from "../locales/languages";
import {setCookieLanguage} from "../stores/CookieStore";

export default async function language() {

  let defaultLanguage = configStoreDefaultLanguage.get();
  let cookieLanguage = Cookie.get("selectedLanguage");
  let selectedLanguage = cookieLanguage ? cookieLanguage : configStoreSelectedLanguage.get();

  if(!selectedLanguage){
    throw "Something went terribly wrong with setting language!"
  }

  if(!supportedLanguages.includes(selectedLanguage)){
    setSelectedLanguage(defaultLanguage);
    return;
  }
  if(!cookieLanguage){
    setCookieLanguage(selectedLanguage);
  }
  setSelectedLanguage(selectedLanguage)

}
